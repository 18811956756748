import { ChatEventReasons, StopChatDecision } from '../constants'

export const getStopChatResolution = (
  eventReason: ChatEventReasons,
  chatBlockedKey: string
): {
  decision: StopChatDecision
} | void => {
  switch (chatBlockedKey) {
    case 'ticket_opening_required':
      if (eventReason === ChatEventReasons.InputFocused) {
        return {
          decision: StopChatDecision.ShowStopChatNotice,
        }
      }
      break

    case 'UnapprovedName':
    case 'UnapprovedRecipientName':
      if (
        eventReason === ChatEventReasons.ChatOpen ||
        eventReason === ChatEventReasons.Send
      ) {
        return {
          decision: StopChatDecision.ShowStopChatNotice,
        }
      }
      break

    /**
     * Исключения, которые образовались в рамках АБ-теста https://redmine.mamba.ru/issues/100748#vip-after-sending
     * и были приняты в кач-ве дефолного поведения с точки зрения бизнеса.
     * И в кач-ве исключения с точки зрения кода.
     */
    case 'contact_limit0':
    case 'VipForIndian':
    case 'new_contact':
    case 'UnreadMessagesLimit':
    case 'vip':
    case 'geo_vip':
    case 'photo_or_vip':
      if (eventReason === ChatEventReasons.Send) {
        return {
          decision: StopChatDecision.OpenStoreFrontVip,
        }
      }
      break

    case 'new_contact_vip':
    case 'not_in_filter':
    case 'age':
    case 'liked_only_filter':
      if (
        eventReason === ChatEventReasons.ChatOpen ||
        eventReason === ChatEventReasons.Send
      ) {
        return {
          decision: StopChatDecision.OpenStoreFrontGift,
        }
      }
      break

    case 'universal-stop-chat':
      if (
        eventReason === ChatEventReasons.ChatOpen ||
        eventReason === ChatEventReasons.Send
      ) {
        return {
          decision: StopChatDecision.ShowStopChatNotice,
        }
      }

    default:
      if (eventReason === ChatEventReasons.Send) {
        return {
          decision: StopChatDecision.ShowStopChatNotice,
        }
      }
  }
}
