import {
  ChatDateFormat,
  MessengerItemTypes,
  PhotoRestrictionStatus,
} from 'components/page/Chat/Messenger/Messenger.constants'
import { CustomPhoto, Photo } from 'api/photo/photo.types'
import { ContactAlias } from 'components/page/Chat/Messenger/Contact/ContactsList.constants'
import { ContactItem } from 'reducers/contactList/contactList.types'
import { ProfileApi5 } from 'reducers/profile/ProfileApi5.types'
import { UniNotice } from 'api/uninotice/UniNotice'
import { MessageReaction } from 'components/page/Chat/Messenger/Reactions/types'
import { ChatMessagePhoto } from './chatMessage.types'

export interface ChatMessageSticker {
  name: string
}

export interface ChatMessageOptions {
  photoUrl: string
  photos: ChatMessagePhoto[]
  photoFrom: string
  photoTo: string
  squareUrl: string
  imageName: string
  sticker: ChatMessageSticker
  // ApplicationMessage
  appName: string
  appUrl: string
  appIcon: string
  // SharedContact
  alias?: ContactAlias
  contactUrl?: string
  contactValue?: string
  // ?
  comment?: string
  answer?: string
  needApprove?: boolean
}

export enum ActionComet {
  Replace = 'replace',
  Remove = 'remove',
  Publish = 'publish',
}

export interface MessageFromCometContent {
  guid: string
  img_url: string
  messages_count: number
  contact: ContactItem
  /** Ниже добавил для типизации, надо будет проверить правильность */
  lastMessageTimestamp?: number
  lastMessage?: ChatMessage
}

export interface MessageEditedCometContent {
  contactUserId: number
  messageId: number
  newText: string
  editDate: string
}

export interface MessageReaderFromCometContent {
  anketaId: number
  contactId: number
}

export interface MessageTypingFromCometContent {
  senderId: number
}

export interface MessageIgnoredFromCometContent {
  ignoredBy: number
  unignoredBy: number
}

export interface ChatMessage {
  // Полностью скопированные поля из метода API5, без маппинга
  id: number
  uuid: string
  user_from_id: number
  user_to_id: number
  folder_to_id: number
  contact_id: string
  created: string // С хера ли стринг спросите сервер
  createdTs: number
  incoming: boolean
  unread: boolean
  type: MessengerItemTypes
  message: string
  message_text: string
  last_edit_date: null | string
  removeAllowed?: boolean
  replyAllowed?: boolean
  options?: ChatMessageOptions
  // Кастомные
  photoLoaded?: boolean
  // Это поле есть у заблокированного пользователя в чате с поддержкой
  photos?: ChatMessagePhoto[]
  // Id фото, на которое был ответ
  repliedPhotoId?: number
  // Отвеченное сообщение
  repliedMessage?: Omit<ChatMessage, 'repliedMessage' | 'repliedPhotoId'>
  // Визуальный элемент, зачем он в данных сообщения?
  textChain?: string
  reactions?: MessageReaction[]
  reactionAllowed?: boolean
  /**
   * Флаг для удаление входящего сообщения
   * https://youtrack.mamba.ru/issue/M-4551#focus=Comments-4-33879.0-0
   */
  removeForRecipientAllowed?: boolean
  isIncoming?: boolean
}

export const TEXT_CHAIN_STATUS = {
  START: 'start',
  MID: 'middle', // А разве используем сокращения ?
  END: 'end',
}
export type TextChain =
  | typeof TEXT_CHAIN_STATUS.START
  | typeof TEXT_CHAIN_STATUS.MID
  | typeof TEXT_CHAIN_STATUS.END

export interface GroupedChatMessage extends ChatMessage {
  textChain: TextChain
}

export interface ChatMessageByDate {
  dateTimestamp: number
  dateFormat: ChatDateFormat
  messages: ChatMessage[]
}

export interface ChatData {
  anketa?: ProfileApi5
  contactId?: number
  contactTypeId?: number
  chatBlockedKey?: string
  chatBlockedReason?: string
  /** Нотайс для текущей ситуации с чатом */
  stopChatNotice?: UniNotice
  isChatBlocked?: boolean
  lastMessageTimestamp?: number
  /** Строка типа "2021-05-18 10:36:58" с датой, когда чат будет удален автоматически */
  autoDeleteDate?: string | null
  autoDeleteDateTs?: number | null
  /** true если чат был инициирован авторизованным пользователем */
  initiatedByOwner?: boolean
  isPrivatePhotoEnabled?: boolean
  isPrivateStreamEnabled?: boolean
  privatePhotoDisablingReason?: UniNotice
  /** Поля, которые приходят для забаненного пользователя из запроса /api/messenger/support/chat */
  contactName?: string
  folderId?: number
  userId?: number
  photo?: {
    smallPhotoUrl: string
    mediumPhotoUrl: string
    hugePhotoUrl: string
    squarePhotoUrl: string
    squareSmallPhotoUrl: string
    hugeWidth: string
    hugeHeight: string
  }
  /** Для HelpDesk скрывает возможность написать в чат поддержки для незаблокированных пользователей */
  hideInput?: boolean
  isMutedByMe?: boolean
}

export type ChatMessengerState = {
  messageSending: boolean
  selectedProfileId: number
  isChangedProfileId: boolean
  chatData: ChatData
  messages: ChatMessage[]
  messagesByDays: ChatMessageByDate[]
  /** Ответ на сообщение собеседника */
  replyMessageId: number | null
  navigateToMessageId: number | null
  isEndOfMessages: boolean
  loadingChat: boolean
  loadedChat: boolean
  chatFetchingServerError: boolean
  firstLoadFinished: boolean
  hasNewIncoming: boolean
  isTyping: boolean
  typingUser: number
  deferredMarkAllReaded: boolean
  forceRefetch: boolean
  stickers: unknown[] // @TODO Типизировать
  photosSelected: CustomPhoto[]
  isSupport: boolean
  contentInput: string
  cursorPositionStart: number
  cursorPositionEnd: number
  statusRequestChat: number // TODO: этот статус берется из src/common/api/functions.ts enum LoadStatus.notRequested, но почему сюда не вставляется через стандартный import падает с ошибкой.
  messagesImageList: Record<number, boolean>
  needScrollBottom: boolean
  updateReadedByContactId: number
  updateContacts: boolean
  updateContactsProgress: boolean
  openSupportTicket: boolean
  /** Флаг говорящий о том, что чат нужно закрыть */
  closeChat: boolean
  isNeedShowStopChat: boolean
  contactsOpened: boolean | null
  editMessageId: number | null
  sendReadedMessageContactId: number
  // Предупреждение о деликатном содержимом при просмотре изображений в переписке
  photosRestrictionStatus: {
    status: PhotoRestrictionStatus
    noRestrictionForMessagesWithIds: number[]
  } | null
  // Поделиться контактом
  outsideContactsFetching: boolean
  outsideContactsFetched: boolean
  outsideContactsFetchingFailed: boolean
  outsideContactsRequested: boolean
  outsideContactUpdating: boolean
  outsideContactUpdated: boolean
  outsideContactUpdatingFailed: boolean
  outsideContactSending: boolean
  outsideContactSent: boolean
  outsideContactSendingFailed: boolean
  outsideContacts: {
    alias: string
    isEmpty: boolean
    contactValue?: string
    contactUrl?: string
  }[]
  reactions: null | Reaction[]
  requestStatus?: MessengerRequestStatus
}

export const enum MessengerRequestStatus {
  Unsent = 'unsent',
  Sent = 'sent',
  Received = 'received',
  Approved = 'approved',
  Declined = 'declined',
}

export type Reaction = {
  id: number
  type: string
  value: string
}

export const initialState: ChatMessengerState = {
  messageSending: false,
  selectedProfileId: 0,
  isChangedProfileId: false,
  /**
   * Крайне неудачное решение положить в объект,
   * из-за этого сыпятся ошибки с undefined.
   * При случае вынести.
   */
  chatData: {} as ChatData,
  messages: [],
  messagesByDays: [],
  replyMessageId: null,
  navigateToMessageId: null,
  isEndOfMessages: false,
  loadingChat: false,
  loadedChat: false,
  chatFetchingServerError: false,
  firstLoadFinished: false,
  hasNewIncoming: false,
  isTyping: false,
  typingUser: 0,
  deferredMarkAllReaded: false,
  forceRefetch: false,
  stickers: [], // @TODO Типизировать
  photosSelected: [],
  isSupport: false,
  contentInput: '',
  cursorPositionStart: 0,
  cursorPositionEnd: 0,
  statusRequestChat: 1, // TODO: этот статус берется из src/common/api/functions.ts enum LoadStatus.notRequested, но почему сюда не вставляется через стандартный import падает с ошибкой.
  messagesImageList: {},
  needScrollBottom: false,
  updateReadedByContactId: 0,
  updateContacts: false,
  updateContactsProgress: false,
  openSupportTicket: false,
  /** Флаг говорящий о том, что чат нужно закрыть */
  closeChat: false,
  isNeedShowStopChat: false,
  contactsOpened: false,
  editMessageId: null,
  sendReadedMessageContactId: 0,
  // Предупреждение о деликатном содержимом при просмотре изображений в переписке
  photosRestrictionStatus: null,
  // Поделиться контактом
  outsideContactsFetching: false,
  outsideContactsFetched: false,
  outsideContactsFetchingFailed: false,
  outsideContactsRequested: false,
  outsideContactUpdating: false,
  outsideContactUpdated: false,
  outsideContactUpdatingFailed: false,
  outsideContactSending: false,
  outsideContactSent: false,
  outsideContactSendingFailed: false,
  outsideContacts: [],
  reactions: null,
}
